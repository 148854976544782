import config from './config'
import SignatureArea from './SignatureArea'
import background from './backgrounds/background.png'
import sign from 'jwt-encode'
import axios from 'axios'

const Container = () => {
  const uploadImage = (pngData, email) => {
    const generateFilename = () => {
      return new Date().toISOString() + '_Signature_' + email + '.png'
    }

    const makeAuthorizationHeader = () => {
      const secret = config.jwtSecret
      const now = Math.round(new Date().getTime() / 1000)
      const data = {
        iat: now,
        exp: now + 60 * 60 * 24,
      }
      return 'Bearer ' + sign(data, secret)
    }

    return axios.post(
      config.uploadUrl,
      {
        filename: generateFilename(),
        payload: pngData.replace(/^data:image\/\w+;base64,/, ''),
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': makeAuthorizationHeader(),
        },
      },
    )
  }

  return (
    <div>
      <div
        className="container"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
        }}
      >
        <div id="logo">
          <img src="assets/logo.png" alt="Unleash" />
        </div>
        <SignatureArea uploadImage={uploadImage} />
      </div>
    </div>
  )
}

export default Container
