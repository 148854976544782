import {useRef, useEffect, useState} from 'react'
import SignaturePad from 'signature_pad'
import Modal from './Modal'
import axios from 'axios'
import config from '../src/config'
import {createAxiosConfig} from './helpers'

let signaturePad

const handleRetinaScreens = canvas => {
  var ratio = Math.max(window.devicePixelRatio || 1, 1)
  canvas.width = canvas.offsetWidth * ratio
  canvas.height = canvas.offsetHeight * ratio
  canvas.getContext('2d').scale(ratio, ratio)
}

const SignatureArea = ({uploadImage}) => {
  const signaturePadCanvas = useRef(null)
  const [signaturePadData, setSignaturePadData] = useState(null)
  const [submitButtonDisabled, greySubmit] = useState(true)
  const [show, showModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [token, setToken] = useState(null)
  const clearSignaturePad = () => {
    signaturePad.clear()
    greySubmit(true)
  }

  useEffect(() => {
    const params = new URLSearchParams(document.location.search)
    const urlToken = params.get('token')
    setToken(urlToken)
  }, [])

  const submitSignature = async () => {
    greySubmit(true)

    const pngData = signaturePad.toDataURL()

    let email

    if (token) {
      const url = `${config.backendURL}/api/users/me`
      const axiosConfig = createAxiosConfig(token)
      try {
        const response = await axios.get(url, axiosConfig)
        email = response.data.email
      } catch (error) {
        console.log('Error in fetching user', error)
      }
    } else {
      email = 'user_unknown'
    }

    await uploadImage(pngData, email)
      .then(result => {
        console.log('Submit SUCCEEDED')
        showModal(true)
      })
      .catch(err => {
        console.log('Submit FAILED: ', err.message)
        setErrorMessage(err.message)
        showModal(true)
      })
    signaturePad.clear()
    greySubmit(true)
  }

  const handlePadResize = canvas => {
    let padData = signaturePad.toData()
    setSignaturePadData(padData)
    handleRetinaScreens(canvas)
  }

  useEffect(() => {
    const canvas = signaturePadCanvas.current
    const options = {
      throttle: 16,
    }
    handleRetinaScreens(canvas)

    window.addEventListener('resize', () => handlePadResize(canvas))
    signaturePad = new SignaturePad(canvas, options)
    signaturePad.clear()
    signaturePad.addEventListener(
      'beginStroke',
      () => {
        greySubmit(false)
      },
      {once: false},
    )
  }, [signaturePadCanvas])

  useEffect(() => {
    if (signaturePadData) {
      signaturePad.fromData(signaturePadData)
      setSignaturePadData(null)
    }
  }, [signaturePadData])

  return (
    <div className="canvas-container">
      <canvas className="signature-pad" ref={signaturePadCanvas}></canvas>
      <div className="button-container">
        <button className="button-submit" onClick={submitSignature} disabled={submitButtonDisabled}>
          Submit
        </button>
        <button className="button-clear" onClick={clearSignaturePad}>
          Clear
        </button>
      </div>
      <Modal show={show} error={errorMessage} hideModal={() => showModal(false)} />
    </div>
  )
}

export default SignatureArea
