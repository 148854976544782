const SuccessMessage = () => (
  <div>
    <h2>Success</h2>
    <h3>Your commitment was captured</h3>
  </div>
)

const ErrorMessage = ({message}) => <h3>{message}</h3>

const Modal = ({show, hideModal, error}) => (
  <div className={`overlay${!show ? ' hidden' : ''}`} onClick={hideModal}>
    <div className={`modal${!show ? ' hidden' : ''}`}>
      {error ? <ErrorMessage message={error} /> : <SuccessMessage />}
    </div>
  </div>
)

export default Modal
